<template>
    <div class="AuxilLedgerList">
        <el-card class="box-card">
            <div slot="header" class="clearfix">
                <el-form :inline="true" class="demo-form-inline" :model="auxilCrkFram" ref="auxilCrkFram">
                    <el-form-item label="类型">
                        <el-select v-model="auxilCrkFram.type">
                            <el-option v-for="(item, index) in typeList" :key="index" :label="item.name"
                                :value="item.type"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="条码">
                        <el-input v-model="auxilCrkFram.auxCode" placeholder="请输入条码查询"></el-input>
                    </el-form-item>
                    <el-form-item label="工具分类">
                        <el-select placeholder="请选择工器具分类名称" v-model="auxilCrkFram.classCode">
                            <el-option v-for="(item, index) in assistClassList" :key="index" :label="item.className"
                                :value="item.classCode"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="工具名称">
                        <el-input v-model="auxilCrkFram.auxName" placeholder="请输入工具名称查询"></el-input>
                    </el-form-item>

                    <el-form-item label="规格型号">
                        <el-input v-model="auxilCrkFram.auxType" placeholder="请输入工具规格型号查询"></el-input>
                    </el-form-item>
                    <!-- <el-form-item label="工具编号">
                        <el-input v-model="auxilCrkFram.auxNum" placeholder="请输入工器具编号查询"></el-input>
                    </el-form-item> -->
                    <el-form-item label="经办人">
                        <el-input v-model="auxilCrkFram.crkJbr" placeholder="请输入出/入库经办人查询"></el-input>
                    </el-form-item>
                    <el-form-item label="出库日期">
                        <el-date-picker v-model="auxilCrkFram.ckDate" format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd"
                            type="date" placeholder="选择出库日期">
                        </el-date-picker>
                    </el-form-item>
                    <!-- <el-form-item label="入库经办人">
                        <el-input v-model="auxilCrkFram.rkJbr" placeholder="请输入入库经办人查询"></el-input>
                    </el-form-item> -->
                    <el-form-item label="入库日期">
                        <el-date-picker v-model="auxilCrkFram.rkDate" format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd"
                            type="date" placeholder="选择入库日期">
                        </el-date-picker>
                    </el-form-item>
                    <!-- <el-form-item label="状态">
                        <el-select placeholder="请选择工器具状态" v-model="auxilCrkFram.status">
                            <el-option v-for="toolStatus in toolStatusList" :key="toolStatus.pKey" :label="toolStatus.pValue"
                                :value="toolStatus.pKey"></el-option>
                        </el-select>
                    </el-form-item> -->
                    <el-form-item>
                        <el-button class="btn" @click="handleSearch" icon="el-icon-search">查询</el-button>
                        <el-button @click="handleReset" icon="el-icon-refresh-right">重置</el-button>
                    </el-form-item>
                </el-form>
                <div class="clearfix">
                    <!-- $router.push({ name: 'auxil-oi-ckmanage' }) -->
                    <el-button class="btn" @click="handleCk" icon="el-icon-truck">出库</el-button>
                    <!-- $router.push({ name: 'auxil-oi-rkmanage' }) -->
                    <el-button type="warning" @click="handleRk" icon="el-icon-truck">入库</el-button>
                    <el-button type="primary" @click="handleDown" icon="el-icon-upload2">导出</el-button>
                </div>
            </div>

            <!-- 列表 -->
            <el-table :data="auxiliaryCrkList" border style="width: 100%" v-loading="loading" element-loading-text="拼命加载中"
                element-loading-spinner="el-icon-loading"
                :header-cell-style="{ background: '#eee', fontWeight: 700, color: '#606266' }">
                <el-table-column prop="seq" label="序号" align="center" width="50px">
                </el-table-column>
                <el-table-column prop="oddNum" label="单号" align="center"> </el-table-column>
                <el-table-column prop="auxCode" label="条码" align="center"> </el-table-column>
                <el-table-column prop="className" label="工具分类" align="center"> </el-table-column>
                <el-table-column prop="auxName" label="工具名称" align="center">
                </el-table-column>
                <el-table-column prop="auxType" label="工具规格型号" align="center">
                </el-table-column>
                <el-table-column prop="auxNum" label="工器具编号" align="center">
                </el-table-column>
                <el-table-column prop="ckJbr" label="出库经办人" align="center">
                </el-table-column>
                <el-table-column prop="ckDate" label="出库日期" align="center">
                </el-table-column>
                <el-table-column prop="ckTime" label="出库时间" align="center">
                </el-table-column>
                <el-table-column prop="predictRkDate" label="预计入库日期" align="center">
                </el-table-column>
                <el-table-column prop="rkJbr" label="入库经办人员" align="center">
                </el-table-column>
                <el-table-column prop="rkDate" label="入库日期" align="center">
                </el-table-column>
                <el-table-column prop="rkTime" label="入库时间" align="center">
                </el-table-column>
                <el-table-column prop="remark" label="评价" align="center">
                </el-table-column>

                <el-table-column label="操作" align="center" width="180">
                    <template slot-scope="scope">
                        <el-button type="text" size="mini" title="详情" @click="handleDetail(scope.row)">详情</el-button>
                    </template>
                </el-table-column>
            </el-table>

            <!-- 分页 -->
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="auxilCrkFram.pageNum" :page-sizes="[5, 10, 20, 50, 100]" :page-size="auxilCrkFram.pageSize"
                layout="total, sizes, prev, pager, next, jumper" :total="total">
            </el-pagination>



            <!-- 出库 -->
            <el-dialog title="辅助工具出库" :visible.sync="cKFormVisible" width="70%" :before-close="closeCkInfo"
                :close-on-click-modal="false">
                <el-card class="box-card">
                    <div slot="header" class="clearfix">
                        <span class="title">工具列表</span>
                        <el-divider></el-divider>
                        <!-- class="demo-form-inline" -->
                        <el-form :inline="true" @submit.native.prevent>
                            <el-form-item label="工具条码">
                                <el-input ref="auxCodeCkRef" v-model="auxCode" @keydown.enter.native="courierCkNumEnterDown"
                                    @keyup.enter.native="courierCkNumEnterUp" placeholder="请输入标签查询" autocomplete="off"
                                    class="auxCode"></el-input>
                                <span class="hint">( 可扫码输入 )</span>
                            </el-form-item>
                        </el-form>
                    </div>

                    <!-- 列表 -->
                    <el-table :data="auxiliaryInfoList" border style="width: 100%;" v-loading="ckloading" 
                        element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading"
                        :header-cell-style="{ background: '#eee', fontWeight: 700, color: '#606266' }" >
                        <el-table-column prop="seq" label="序号" align="center" width="50px">
                        </el-table-column>
                        <el-table-column prop="className" label="工具分类" align="center"> </el-table-column>
                        <el-table-column prop="auxName" label="工具名称" align="center">
                        </el-table-column>
                        <el-table-column prop="auxType" label="工具规格型号" align="center">
                        </el-table-column>
                        <el-table-column prop="auxNum" label="工器具编号" align="center">
                        </el-table-column>
                        <el-table-column prop="location" label="存放位置" align="center">
                        </el-table-column>
                        <el-table-column prop="status_text" label="状态" align="center">
                        </el-table-column>
                        <el-table-column prop="auxPzbz" label="配置标准" align="center">
                        </el-table-column>
                        <el-table-column prop="auxXypz" label="现有配置" align="center">
                        </el-table-column>
                        <el-table-column label="操作" align="center" width="180">
                            <template slot-scope="scope">
                                <el-button type="text" size="mini" title="删除" class="delColor"
                                    @click="handleCkDel(scope.row.auxCode)">删除</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-card>

                <div class="work">
                    <span class="title">工作任务</span>
                    <el-divider></el-divider>
                    <el-form :model="auxilCkInfo" :rules="ckrules" ref="auxilCkInfo">
                        <el-form-item label="工作地点" :label-width="formLabelWidth" prop="workplace">
                            <el-input v-model="auxilCkInfo.workplace" autocomplete="off" placeholder="请填写工作地点"
                                style="width:60%"></el-input>
                        </el-form-item>
                        <el-form-item label="工作内容" :label-width="formLabelWidth" prop="workContent">
                            <el-input v-model="auxilCkInfo.workContent" autocomplete="off" placeholder="请填写工作内容"
                                style="width:60%"></el-input>
                        </el-form-item>
                        <el-form-item label="出库经办人" :label-width="formLabelWidth" prop="ckJbr">
                            <el-input v-model="auxilCkInfo.ckJbr" autocomplete="off" placeholder="请填写出库经办人"
                                style="width:60%"></el-input>
                        </el-form-item>
                        <el-form-item label="出库日期" :label-width="formLabelWidth" prop="ckDate">
                            <el-date-picker v-model="auxilCkInfo.ckDate" format="yyyy-MM-dd" value-format="yyyy-MM-dd"
                                type="date" placeholder="选择日期">
                            </el-date-picker>
                        </el-form-item>
                        <el-form-item label="出库时间" :label-width="formLabelWidth" prop="ckTime">
                            <el-time-picker v-model="auxilCkInfo.ckTime" value-format="HH:mm:ss" :picker-options="{
                                selectableRange: '00:00:00 - 23:59:59'
                            }" placeholder="任意时间点">
                            </el-time-picker>
                        </el-form-item>
                    </el-form>
                    <div slot="footer" class="dialog-footer">
                        <el-button type="primary" @click="ckSure('auxilCkInfo')">提交</el-button>
                    </div>
                </div>
            </el-dialog>


            <!-- 入库 -->
            <el-dialog title="辅助工具入库" :visible.sync="rKFormVisible" width="70%" :before-close="closeRkInfo"
                :close-on-click-modal="false">
                <el-card class="box-card">
                    <div slot="header" class="clearfix">
                        <span class="title">工具列表</span>
                        <el-divider></el-divider>
                        <!-- class="demo-form-inline" -->
                        <el-form :inline="true" @submit.native.prevent>
                            <el-form-item label="工具条码">
                                <el-input ref="auxCodeRkRef" v-model="auxCode" @keydown.enter.native="courierRkNumEnterDown"
                                    @keyup.enter.native="courierRkNumEnterUp" placeholder="请输入标签查询" autocomplete="off"
                                    class="auxCode"></el-input>
                                <span class="hint">( 可扫码输入 )</span>
                            </el-form-item>
                        </el-form>
                    </div>

                    <!-- 列表 -->
                    <el-table :data="auxilCrkList" border style="width: 100%" v-loading="rkloading"
                        element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading"
                        :header-cell-style="{ background: '#eee', fontWeight: 700, color: '#606266' }">
                        <el-table-column prop="seq" label="序号" align="center" width="50px">
                        </el-table-column>
                        <el-table-column prop="oddNum" label="单号" align="center"> </el-table-column>
                        <el-table-column prop="className" label="工具分类" align="center"> </el-table-column>
                        <el-table-column prop="auxName" label="工具名称" align="center">
                        </el-table-column>
                        <el-table-column prop="auxType" label="工具规格型号" align="center">
                        </el-table-column>
                        <el-table-column prop="auxNum" label="工器具编号" align="center">
                        </el-table-column>
                        <el-table-column prop="ckJbr" label="出库经办人" align="center">
                        </el-table-column>
                        <el-table-column prop="ckDate" label="出库日期" align="center">
                        </el-table-column>
                        <el-table-column prop="ckTime" label="出库时间" align="center">
                        </el-table-column>
                        <el-table-column label="操作" align="center" width="180">
                            <template slot-scope="scope">
                                <el-button type="text" size="mini" title="删除" class="delColor"
                                    @click="handleRkDel(scope.row.auxCode)">删除</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-card>

                <div class="work">
                    <span class="title">工作任务</span>
                    <el-divider></el-divider>
                    <el-form :model="auxilRkInfo" :rules="rkRules" ref="auxilRkInfo">
                        <el-form-item label="入库经办人" :label-width="formLabelWidth" prop="rkJbr">
                            <el-input v-model="auxilRkInfo.rkJbr" autocomplete="off" placeholder="请填写入库经办人"
                                style="width:60%"></el-input>
                        </el-form-item>
                        <el-form-item label="入库日期" :label-width="formLabelWidth" prop="rkDate">
                            <el-date-picker v-model="auxilRkInfo.rkDate" format="yyyy-MM-dd" value-format="yyyy-MM-dd"
                                type="date" placeholder="选择日期">
                            </el-date-picker>
                        </el-form-item>
                        <el-form-item label="入库时间" :label-width="formLabelWidth" prop="rkTime">
                            <el-time-picker v-model="auxilRkInfo.rkTime" value-format="HH:mm:ss" :picker-options="{
                                selectableRange: '00:00:00 - 23:59:59'
                            }" placeholder="任意时间点">
                            </el-time-picker>
                        </el-form-item>
                        <el-form-item label="使用评价" :label-width="formLabelWidth" prop="useEvaluate">
                            <el-input v-model="auxilRkInfo.useEvaluate" autocomplete="off" placeholder="请填写使用评价"
                                style="width:60%"></el-input>
                        </el-form-item>
                    </el-form>
                    <div slot="footer" class="dialog-footer">
                        <el-button type="primary" @click="rkSure('auxilRkInfo')">确 定</el-button>
                    </div>
                </div>
            </el-dialog>
        </el-card>
    </div>
</template>
<script>

import { getAssistClassAll } from '@/api/assistClass.js'
import { searchAuxilCrkInfo, ckInventory, rkInventory, getCkInfo } from '@/api/auxiliaryCrk.js'
import { getAuxiliaryInfo } from '@/api/auxilInfo.js'
import { downloadExcel } from '@/api/download.js'
export default {
    data() {
        return {
            auxiliaryCrkList: [],
            assistClassList: [],
            auxilCrkFram: {
                type: '00',
                auxCode: null,
                classCode: null,
                auxName: null,
                auxType: null,
                auxNum: null,
                crkJbr: null,
                ckJbr: null,
                ckDate: null,
                rkJbr: null,
                rkDate: null,
                pageNum: 1,
                pageSize: 10,
            },
            total: 0,
            loading: false,
            typeList: [{ type: '00', name: '当前出入库记录' }, { type: '01', name: '历史出入库记录' }],
            cKFormVisible: false,     //出库
            auxCode: null,
            auxiliaryInfoList: [],
            auxilCkInfo: {
                auxiliaryInfoList: [],
                workplace: null,
                workContent: null,
                ckJbr: null,
                ckDate: null,
                ckTime: null
            },
            ckrules: {
                workplace: [{ required: true, message: '请填写工作地点' }],
                workContent: [{ required: true, message: '请填写工作内容' }],
                ckJbr: [{ required: true, message: '请填写出库经办人' }],
                ckDate: [{ required: true, message: '请选择出库日期' }],
                ckTime: [{ required: true, message: '请选择出库时间' }],
            },
            ckloading: false,
            formLabelWidth: '100px',
            ckKeyDownTime: 0,
            ckKeyUpTime: 0,
            // auxCode: null,
            rKFormVisible: false,//入库
            auxilCrkList: [],
            auxilRkInfo: {
                auxilCrkList: [],
                rkJbr: null,
                rkDate: null,
                rkTime: null,
                remark: null,
                useEvaluate: null
            },
            rkRules: {
                rkJbr: [{ required: true, message: '请填写入库经办人' }],
                rkDate: [{ required: true, message: '请选择入库日期' }],
                rkTime: [{ required: true, message: '请选择入库时间' }],
            },
            rkloading: false,
            // focus: false,
            dialogFormVisible: false,
            rkKeyDownTime: 0,
            rkKeyUpTime: 0,


        }
    },
    mounted() {
        this.loadToolClass()
        this.loadAuxiliaryCrkInfo()
    },
    methods: {

        //列表start 
        //分页
        handleSizeChange(val) {
            this.auxilCrkFram.pageSize = val;
            this.auxilCrkFram.pageNum = 1;
            this.loadAuxiliaryCrkInfo();
        },
        handleCurrentChange(val) {
            this.auxilCrkFram.pageNum = val;
            this.loadAuxiliaryCrkInfo();
        },
        //加载工具分类
        async loadToolClass() {
            await getAssistClassAll().then(res => {
                if (res.code === '000000') {
                    this.assistClassList = res.t
                }
            })
        },
        //加载出入库信息
        loadAuxiliaryCrkInfo() {
            this.loading = true
            searchAuxilCrkInfo(this.auxilCrkFram).then(res => {
                setTimeout(() => {
                    this.loading = false
                    if (res.code === '000000') {
                        this.auxiliaryCrkList = res.t.list
                        this.total = res.t.total
                        var count = 1;
                        this.auxiliaryCrkList.forEach((item) => {
                            item.seq = count++
                        })
                    }
                }, 500)

            })
        },
        //出入库详情
        handleDetail({ id }) {
            this.$router.push({
                name: 'auxil-oi-detail',
                params: {
                    id,
                    type: this.auxilCrkFram.type
                }
            })
        },
        //搜索
        handleSearch() {
            this.loadAuxiliaryCrkInfo(this.auxilCrkFram);
        },
        //重置
        handleReset() {
            this.auxilCrkFram = {
                type: '00',
                auxCode: null,
                classCode: null,
                auxName: null,
                auxType: null,
                auxNum: null,
                ckJbr: null,
                ckDate: null,
                rkJbr: null,
                rkDate: null,
                pageNum: 1,
                pageSize: 10,
            };
            this.loadAuxiliaryCrkInfo(this.auxilCrkFram);
        },
        // 列表end


        //出库
        handleCk() {
            this.cKFormVisible = true
            this.onAuxCodeCkRef()
        },
        //获取出库焦点
        onAuxCodeCkRef() {
            this.$nextTick(() => {
                this.$refs.auxCodeCkRef.focus()
            })
        },

        //根据条码回显辅助工器具
        loadAuxilaryInfo() {
            if (this.auxCode) {
                this.ckloading = true
                getAuxiliaryInfo(this.auxCode).then(res => {
                    setTimeout(() => {
                        this.ckloading = false
                        if (res.code === '000000') {
                            if (this.auxiliaryInfoList.length > 0) {
                                if (this.auxiliaryInfoList.findIndex(item => item.auxCode === res.t.auxCode) === -1) {
                                    this.auxiliaryInfoList.push(res.t)
                                } else {
                                    this.$message.warning('该辅助工具已添加到出库清单中，请勿重复添加！')
                                }
                            } else {
                                this.auxiliaryInfoList.push(res.t)
                            }
                            var count = 1;
                            this.auxiliaryInfoList.forEach((item) => {
                                item.seq = count++
                                switch (item.status) {
                                    case '00':
                                        item.status_text = '已入库'
                                        break;
                                    case '01':
                                        item.status_text = '已出库'
                                        break;
                                    case '02':
                                        item.status_text = '使用未返回'
                                        break;
                                    case '03':
                                        item.status_text = '报废'
                                        break;
                                    default:
                                        item.status_text = ''
                                        break;
                                }
                            })
                        }
                        this.auxCode = null
                    }, 200)

                })
            }

        },

        //提交出库单
        ckSure(auxilCkInfo) {
            this.$refs[auxilCkInfo].validate(valid => {
                if (valid) {
                    this.auxilCkInfo.auxiliaryInfoList = this.auxiliaryInfoList
                    console.log(this.auxilCkInfo)
                    ckInventory(this.auxilCkInfo).then(res => {
                        if (res.code === '000000') {
                            this.$message.success('出库成功')
                        }

                        this.$refs[auxilCkInfo].resetFields()
                        this.cKFormVisible = false
                        // this.focus = true
                        this.auxiliaryInfoList = []
                        this.loadAuxiliaryCrkInfo()
                    })

                }
            })
        },
        //移除需要出库的辅助工具
        handleCkDel(auxCode) {
            this.$confirm('是否移除？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let auxiIndex = this.auxiliaryInfoList.findIndex(item => item.auxCode === auxCode)
                if (auxiIndex !== -1) {
                    this.auxiliaryInfoList.splice(auxiIndex, 1)
                    this.$message.success('移除成功！')
                } else {
                    this.$message.error('移除失败！')
                }
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消报废'
                })
            })
        },

        courierCkNumEnterDown() {
            this.ckKeyDownTime = new Date().getTime()
        },
        courierCkNumEnterUp() {
            this.ckKeyUpTime = new Date().getTime()
            if (this.ckKeyUpTime - this.ckKeyDownTime < 30) {
                console.log("扫码完成")
                this.loadAuxilaryInfo()
            } else {
                this.loadAuxilaryInfo()
            }
        },
        //关闭出库弹框
        closeCkInfo() {
            this.$refs['auxilCkInfo'].resetFields();
            this.cKFormVisible = false
            this.loadAuxiliaryCrkInfo()
        },
        // 出库end



        //入库start
        handleRk() {
            this.rKFormVisible = true
            this.onAuxCodeRkRef()
        },
        //获取入库焦点
        onAuxCodeRkRef() {
            this.$nextTick(() => {
                this.$refs.auxCodeRkRef.focus()
            })
        },
        //根据条码加载出库信息
        loadAuxilCkInfo() {
            if (this.auxCode) {
                this.rkloading = true
                getCkInfo(this.auxCode).then(res => {
                    setTimeout(() => {
                        this.rkloading = false
                        if (res.code === '000000') {
                            if (this.auxilCrkList.length > 0) {
                                if (this.auxilCrkList.findIndex(item => item.auxCode === res.t.auxCode) === -1) {
                                    this.auxilCrkList.push(res.t)
                                } else {
                                    this.$message.warning('该辅助工具已添加到入库清单中，请勿重复添加！')
                                }
                            } else {
                                this.auxilCrkList.push(res.t)
                            }

                            var count = 1;
                            this.auxilCrkList.forEach((item) => {
                                item.seq = count++
                            })
                        }
                        this.auxCode = null
                    }, 200)

                })
            }

        },
        //提交入库信息
        rkSure(auxilRkInfo) {
            this.$refs[auxilRkInfo].validate(valid => {
                if (valid) {
                    this.auxilRkInfo.auxilCrkList = this.auxilCrkList
                    console.log(this.auxilRkInfo)
                    rkInventory(this.auxilRkInfo).then(res => {
                        if (res.code === '000000') {
                            if (res.t) {
                                this.$message.success(res.t)
                            } else {
                                this.$message.success('入库成功')
                            }

                        }

                        this.$refs[auxilRkInfo].resetFields()
                        this.rKFormVisible = false
                        this.auxilCrkList = []
                        this.loadAuxiliaryCrkInfo()
                    })

                }
            })
        },

        //移除要进行入库的信息
        handleRkDel(auxCode) {
            this.$confirm('是否移除？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let auxiIndex = this.auxilCrkList.findIndex(item => item.auxCode === auxCode)
                if (auxiIndex !== -1) {
                    this.auxilCrkList.splice(auxiIndex, 1)
                    this.$message.success('移除成功！')
                } else {
                    this.$message.error('移除失败！')
                }
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消报废'
                })
            })
        },

        courierRkNumEnterDown() {
            this.rkKeyDownTime = new Date().getTime()
        },
        courierRkNumEnterUp() {
            this.rkKeyUpTime = new Date().getTime()
            if (this.rkKeyUpTime - this.rkKeyDownTime < 30) {
                console.log("扫码完成")
                this.loadAuxilCkInfo()
            } else {
                this.loadAuxilCkInfo()
            }
        },

        //关闭出库弹框
        closeRkInfo() {
            this.$refs['auxilRkInfo'].resetFields();
            this.rKFormVisible = false
            this.loadAuxiliaryCrkInfo()
        },
        //入库end

        //导出
        handleDown() {
            this.$fullScreenLoading.show("导出中...");
           
            downloadExcel(this.auxilCrkFram, '/auxiliaryCrk/downloadExcel').then(res => {
                setTimeout(() => {
                    let blob = new Blob([res.data], {
                        type: "application/octet-stream",
                    });
                    // 创建一个a标签
                    var tempLink = document.createElement("a");
                    const fileNames = res.headers['content-disposition']
                    console.log(fileNames)
                    const regFileNames = fileNames.match(/=(.*)$/)[1]
                    console.log(regFileNames)
                    // let filename = "自定义文件名称"+ '.xls'
                    // 将blob对象转为一个URL
                    var blobURL = window.URL.createObjectURL(blob);

                    // 隐藏a标签
                    tempLink.style.display = "none";
                    // 设置a标签的href属性为blob对象转化的URL
                    tempLink.href = blobURL;
                    tempLink.download = decodeURI(regFileNames)// 下载后文件名
                    // 将a标签添加到body当中
                    document.body.appendChild(tempLink);
                    // 启动下载
                    tempLink.click();
                    // 下载完毕删除a标签
                    document.body.removeChild(tempLink);
                    window.URL.revokeObjectURL(blobURL);
                    this.$fullScreenLoading.hide();
                    this.$message({
                        message: "导出成功~",
                        type: "success",
                    });
                }, 500)


            })
        },

    }
}
</script>

<style lang="scss" scoped>
.AuxilLedgerList {

    .el-pagination,
    .clearfix {
        text-align: left;
    }

    .delColor {
        color: red;
    }


    .el-card {
        position: relative;
        text-align: left;
    }


    .el-pagination,
    .el-form-item,
    .clearfix {
        text-align: left;

        .auxCode {
            width: 600px;
            margin-right: 20px;
        }

        .hint {
            color: red;
        }
    }

    .ckSubmit {
        text-align: right;
    }


    .delColor {
        color: red;
    }

    .title {
        font-size: 16px;
        font-weight: 700;
        color: #000;
    }

    .work {
        text-align: left;
        margin-top: 20px;
    }

    .dialog-footer {
        width: 70%;
        text-align: left;
        margin-left: 100px;
    }

}
</style>